<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'rec-post-offensive-warning',
  components: {
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  props: {
    send: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      processing: false,
      closeModal: false,
    }
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    this.$emit('closeOffensiveWarning')
    document.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    async onCreate() {
      this.processing = true

      try {
        await this.send()
        this.onClose()
      } catch (error) {
        this.error = get(error, 'response.data.message', 'Network error')
      }

      this.processing = false
    },
    onClose() {
      modal.hide()
    },
    onKeyUp(e) {
      if (!this.closeModal) {
        this.closeModal = true
        return
      }
      if (this.closeModal && e.keyCode === 13) {
        this.onCreate()
      }
    },
  },
}
</script>

<template>
  <base-mdl
    :layout="false"
    :closable="!processing"
    width="xs"
    @hide="$emit('closeOffensiveWarning')"
  >
    <div class="relative p-5 sm:p-[30px]">
      <div class="absolute top-0 right-0 m-5 sm:m-[30px]">
        <base-button icon="plain/close" @click="onClose" />
      </div>

      <div class="mt-[5px] mb-6 text-center">
        <base-icon
          :svg="error ? 'rec/bin' : 'rec/pen'"
          :size="64"
          class="mx-auto text-fg-error"
        />
      </div>
      <div class="text-center">
        <template v-if="error">
          <h2 class="leading-tight">Error while creating Post</h2>
          <p class="mt-[30px]">
            {{ error }}
          </p>
          <p>Please, try again later.</p>
          <base-button class="mt-10" full-width @click="onClose">
            Close
          </base-button>
        </template>
        <template v-else>
          <h2 class="leading-tight">
            Are you sure you wish to create this Post?
          </h2>
          <p class="mt-[30px]">Inappropriate language has been detected</p>
          <base-button
            class="mt-10"
            full-width
            :loading="processing"
            @click="onCreate"
          >
            Create
          </base-button>
        </template>
      </div>
    </div>
  </base-mdl>
</template>
